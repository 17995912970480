import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import TwoColTable from "../components/TwoColTable"
import Breadcrumbs from "../components/Breadcrumbs"
import { Grid, Container, Image } from 'semantic-ui-react'
import Carousel from "../components/Carousel"

export const query = graphql`
  query ContentPageQuery($id: String) {
    content:strapiContentPage(strapiId: { eq: $id }) {
      strapiId
      PageTitle
      Content {
        RichText
        TwoColHeaders {
          Cell1
          Cell2
        }
        TwoColRow {
          Cell1
          Cell2
        }
        Url
        Text
        Target
        Image {
          childImageSharp {
            sizes(maxWidth: 950) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        Title
        Slides {
          Image {
            childImageSharp {
              sizes(maxWidth: 950) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  }
`

const ContentPage = ({ data, location }) => {
  const page = data.content;
  return (
    <Layout location={location} >
      <Grid stackable>
        <Grid.Row>
          <Container className="maininner">
              <Breadcrumbs className="content-breadcrumbs" PageTitle={page.PageTitle} />
              <article className="content-article">
                <header>
                  <h1>{page.PageTitle}</h1>
                </header>
                <div>
                  {page.Content.map((c, i) => {
                    if (c.RichText) {
                      return <ReactMarkdown source={c.RichText} key={i} />
                    }
                    else if (c.TwoColRow) {
                      return <TwoColTable header={c.TwoColHeaders} rows={c.TwoColRow} key={i} />
                    }
                    else if (c.Url) {
                      if (c.Image) {
                        return (
                          <a href={c.Url} target={c.Target} key={i}>
                            <Image fluid src={c.Image?.childImageSharp?.sizes?.src} />
                          </a>)
                      }
                      else {
                        return <a href={c.Url} target={c.Target} className='ui button' key={i}>{c.Text}</a>
                      }

                    }
                    else if (c.Slides) {
                      return <Carousel Images={c.Slides} key={i} />
                    }
                    return null;
                  })}
                </div>
              </article>
          </Container>
        </Grid.Row>
      </Grid>
    </Layout>
  )
}

export default ContentPage
