import React from "react"
import { Breadcrumb } from "semantic-ui-react"
import { Link } from "gatsby"

const Breadcrumbs = ({ className, PageTitle }) => {
  const BreadcrumStyle = {
    marginTop: "15px",
    marginLeft: "15px",
    marginRight: "15px",
  }
  return (
    <Breadcrumb style={BreadcrumStyle}>
      <Breadcrumb.Section className = "home-title" as={Link} to="/">
        Home
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right chevron" />
      <Breadcrumb.Section className = "breadcrumb-title">{PageTitle}</Breadcrumb.Section>
    </Breadcrumb>
  )
}

export default Breadcrumbs
